import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_6.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";

export function Contacts() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Contacts
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '50%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'justify'
                        }}>
                            <div className='tier-sub-label'>Epic Vacations</div>
                            <div style={{ marginTop: '10px'}}>
                                Epic Member Care Services
                                <br />Mon-Fri: 8am-5pm (EST)
                                <br />1-855-869-3742, Option 0
                                <br />membercare@myepicvacations.com
                            </div>

                            <div className='tier-sub-label'>Reservations</div>
                            <div style={{ marginTop: '10px'}}>
                                <b>Epic Escapes and Epic Vacations</b>
                                <br />Epic/RCI Guide
                                <br />Mon-Fri: 8:30am – 9pm (EST)
                                <br />Sat: 9am-6pm (EST)
                                <br />1-844-433-5400
                            </div>
                            <div style={{ marginTop: '10px'}}>
                                <b>Epic Experiences Member Support</b>
                                <br />Mon-Fri: 9am - 9pm EST
                                <br />Sat-Sun: 9am - 5pm EST
                                <br />Reservations:
                                <br />1-858-622-2904
                                <br />(0800) 883-0747 (UK Toll Free)
                                <br />(800) 681-1535 (MX Toll Free)
                            </div>

                            <div className='tier-sub-label'>Epic Membership Sales</div>
                            <div style={{ marginTop: '10px'}}>
                                Mon-Fri: 8:30am - 5pm EST
                                <br />1-843-842-6336
                                <br />sales@myepcivacations.com
                            </div>

                            <div className='tier-sub-label'>Billing and Loan Services</div>
                            <div style={{ marginTop: '10px'}}>
                                Equiant Financial Services, Inc.
                                <br />Mon-Fri: 8am-5pm (MST)
                                <br />Customer Service – (800) 841-3268
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}