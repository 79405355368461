import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_2.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";

export function MembershipDues() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Epic Vacations Membership Dues
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '70%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'justify'
                        }}>
                            <div className='tier-sub-label'>Annual Membership Dues</div>
                            <div>Initial Membership Dues are part of your enrollment expenses. Membership Dues statements are sent out each September and must be paid by October 1. Timely payment of Membership Dues assures Epic Base Points will be deposited your account in January.</div>
                            <div className='tier-sub-label'>Epic Vacations Billing and Loan Services</div>
                            <div>Epic Vacations currently utilizes Equiant Financial Services Inc. for loan and member dues processing.</div>
                            <div style={{ marginTop: '20px'}}>
                                Equiant Financial Services Inc.
                                <br />P.O. Box 78843
                                <br />Phoenix, AZ 85062
                                <br />800-841-3268
                                <br />Mon-Fri: 8am-5pm (MST)
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}